/**
 * Timetastic
 * Customers page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import CaseStudies from "src/components/caseStudies"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Data
import capterraReviews from "src/content/customers/capterraReviews"

// Styles
import "src/styles/templates/customers.scss"
import "src/styles/animations/customers.scss"

// SEO
const title = "Our customers and their reviews"
const description = "Timetastic customers, success stories and case studies."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-customers-main">
          <section className="c-section c-customers-hero is-animated">
            <div className="u-inner u-text-centre-desktop">
              <h1 className="h h2 c-customers-hero__title">
                Switch to Timetastic
              </h1>
              <h3 className="h4">
                It could be the{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">best thing</span> you do this
                year
              </h3>
            </div>
          </section>

          <div className="c-section is-unanimated">
            <div className="u-inner u-inner--l">
              <div className="c-video-grid">
                <div className="c-video">
                  <div className="c-video__summary">
                    Sarah is an HR Director, and was swamped by request forms and
                    an out of date wall planner. But now with Timetastic she’s
                    gone paperless and brought smiles to all her colleagues.
                  </div>
                  <div className="c-video__player">
                    <iframe
                      title="Timetastic: Sarah a customer story video"
                      src="https://player.vimeo.com/video/359051631?color=d03985&amp;title=0&amp;byline=0&amp;portrait=0"
                      allowFullScreen={true}
                      loading="eager"
                    ></iframe>
                  </div>
                </div>
                <div className="c-video">
                  <div className="c-video__summary">
                    Dave runs his own garage, and frequently had too many
                    mechanics off at the same time. Not anymore — he’s using
                    Timetastic, planning things better and keeping his customers
                    happy.
                  </div>
                  <div className="c-video__player">
                    <iframe
                      title="Timetastic: Dave, a customer story video"
                      src="https://player.vimeo.com/video/359051449?color=d03985&amp;title=0&amp;byline=0&amp;portrait=0"
                      allowFullScreen={true}
                      loading="eager"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="c-section is-unanimated">
            <div className="u-inner u-inner--l">
              <h2 className="h h3">
                Businesses just like yours, <br />
                getting very{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">real results</span> using
                Timetastic
              </h2>
              <CaseStudies />
            </div>
          </section>

          {/* Reviews grid */}
          <section className="c-section" id="reviews">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated">
                <h2 className="h h3">
                  We asked our customers:
                </h2>
                <h3 className="h4">
                  “What’s the{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">best thing</span> about
                  Timetastic?”
                </h3>
              </div>
              <div className="c-reviews-grid-alt">
                {capterraReviews.map((review, index) => {
                  return (
                    <div
                      className="c-review-l-wrapper is-unanimated"
                      key={`review-l-${index}`}
                    >
                      <div className="c-review-l">
                        <div
                          className="c-review-l__content"
                          dangerouslySetInnerHTML={{
                            __html: review.review,
                          }}
                        ></div>
                        <div className="c-review-l__reviewer">
                          {review.reviewer}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>

          <FreeTrialAlt
            body="Over 7,000 small and large companies manage their team’s absence with Timetastic"
            buttonCopy="Start free trial today"
            title="Get one month free!"
            trackCtaLabel="CustomersSignUp"
          />

        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default;
